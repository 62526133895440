(function sliderAvisVerifies() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'hp_info-card-reinsurance';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }
  const getGlobalRateAndReview = GlobalSite.checkDependency('GlobalSite.getGlobalRateAndReview');
  const getPercentRate = GlobalSite.checkDependency('GlobalSite.getPercentRate');
  $(async () => {
    const $rateBlock = $('.avisverifies__star-container--rate');
    const $gradeBlock = $('span.avisverifies__grade--value');
    const { globalRate } = await getGlobalRateAndReview();
    const globalRateToNumber = Number(globalRate);
    const isFloat = !Number.isInteger(globalRateToNumber);

    const formattedRate = globalRateToNumber.toFixed(isFloat ? 1 : 0);

    $gradeBlock.html(formattedRate.toString().replace('.', ','));
    $rateBlock.css('width', getPercentRate(globalRate));
  });
})();
