(function hpSlider() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'hp_slider';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }
  $(() => {
    const $amountInput = $('.hp-slider-container #credit-simulator-value--slider');
    const $startSimulatorButton = $('.hp-slider-container #credit-simulator-button--slider');
    const $amountMessage = $(
      '.hp-slider-container .credit-simulator .credit-simulator__amount__text',
    );
    const $amountErrorMessage = $(
      '.hp-slider-container .credit-simulator .credit-simulator__amount__text.error',
    );
    const $accessibleAmountMessage = $(
      '.hp-slider-container .credit-simulator .accessible-message',
    );
    const $accessibleAmountErrorMessage = $(
      '.hp-slider-container .credit-simulator .accessible-message-error',
    );

    const creditSimuUi = GlobalSite.checkDependency('GlobalSite.creditSimulatorUi')(
      $amountInput,
      $startSimulatorButton,
      $amountMessage,
      $amountErrorMessage,
      $accessibleAmountMessage,
      $accessibleAmountErrorMessage,
    );
    creditSimuUi.domHandler();

    $('.hp-slider-slick').slick({
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow:
        '<button class="slick-prev show-for-large" type="button"><span class="i-arrow-left-circle"></span><span class="slick-sr-only">Slide précédente</span></button>',
      nextArrow:
        '<button class="slick-next show-for-large" type="button"><span class="i-arrow-right-circle"></span><span class="slick-sr-only">Slide suivante</span></button>',
      dots: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            autoplay: false,
            draggable: false,
            touchMove: false,
            swipe: false,
            dots	:false
          },
        },
      ],
      customPaging: (slider, i) =>
        $(`<button type="button"><span class="slick-dot-icon"></span><span class="slick-sr-only">Aller vers la slide ${i + 1}</span></button>`,
        ),
    });
  });
})();
