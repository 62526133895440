(function hpSlider() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'hp_banner';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }
  $(() => {
    const $amountInput = $('.hp-banner-container #credit-simulator-value--slider');
    const $startSimulatorButton = $('.hp-banner-container #credit-simulator-button--slider');
    const $amountMessage = $(
      '.hp-banner-container .credit-simulator .credit-simulator__amount__text',
    );
    const $amountErrorMessage = $(
      '.hp-banner-container .credit-simulator .credit-simulator__amount__text.error',
    );
    const $accessibleAmountMessage = $(
      '.hp-banner-container .credit-simulator .accessible-message',
    );
    const $accessibleAmountErrorMessage = $(
      '.hp-banner-container .credit-simulator .accessible-message-error',
    );

    const creditSimuUi = GlobalSite.checkDependency('GlobalSite.creditSimulatorUi')(
      $amountInput,
      $startSimulatorButton,
      $amountMessage,
      $amountErrorMessage,
      $accessibleAmountMessage,
      $accessibleAmountErrorMessage,
    );
    creditSimuUi.domHandler();

  });
})();
